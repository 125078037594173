<script lang="ts" setup>
import type { LightStealthPath } from '@/types'

const route = useRoute()

const path = computed(() => {
  return route.path as LightStealthPath
})
</script>

<template>
  <app-iframe :path="path" />
</template>
