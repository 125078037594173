<script lang="ts" setup>
import type { MaybeRef } from 'vue'
import { useI18n } from 'vue-i18n'
import { logDownloadAccountVerificationSampleCsv, logDownloadAccountVerificationTemplateCsv, logOpenAccountVerificationWizard } from '@/utils/logger'

defineOptions({
  name: 'AccountVerificationTemplateAndGuidelines',
})

const route = useRouter()
const { t } = useI18n()

function handleAddAccounts() {
  route.push({ name: 'account-verification-wizard' })
  logOpenAccountVerificationWizard()
}

const sampleTemplateLink = ref<HTMLAnchorElement | null>(null)
const emptyTemplateLink = ref<HTMLAnchorElement | null>(null)

function handleDownloadFile(link: MaybeRef<HTMLAnchorElement | null>) {
  unref(link)?.click()
  if (link === sampleTemplateLink.value)
    logDownloadAccountVerificationSampleCsv()
  else if (link === emptyTemplateLink.value)
    logDownloadAccountVerificationTemplateCsv()
}
</script>

<template>
  <div class="bg-pp-logo relative flex flex-col gap-4 rounded-3 bg-pp-green pa-2 color-white">
    <div class="i-custom:pp-logo-vector-verification absolute right-0 top-0 z-0 h-100% w-150 hidden lg:block" />
    <div class="z-1 flex flex-col justify-between gap-2 lg:flex-row lg:items-center">
      <div class="flex flex-1 flex-col gap-2">
        <h4 class="px-2 text-base font-500">
          {{ t('account_verification.template') }}
        </h4>
        <h4 class="px-2 text-sm">
          {{ t('account_verification.hint') }}
        </h4>
      </div>
      <div class="max-w-1/2 flex flex-col lg:items-end">
        <BaseButton color="primary" size="md" @click="handleAddAccounts">
          <div class="flex items-center gap-2 font-semibold">
            <div class="i-custom:users-plus h-5 w-5 shrink-0" />
            {{ t('account_verification.add_accounts') }}
          </div>
        </BaseButton>
      </div>
    </div>
    <div class="z-1 flex flex-col justify-between gap-2 lg:flex-row">
      <div class="flex flex-1 items-center justify-between gap-2 border-1 border-gray-200/20 rounded-3 bg-white bg-opacity-20 pa-4">
        <div>
          <p class="text-sm font-500">
            {{ t('account_verification.sample_file') }}
          </p>
          <p class="text-sm">
            {{ t('account_verification.sample_file_hint') }}
          </p>
        </div>
        <div>
          <BaseButton color="secondary" size="md" @click="handleDownloadFile(sampleTemplateLink)">
            <div class="flex items-center gap-2">
              <p class="font-500">
                {{ t('account_verification.download') }}
              </p>
              <div class="i-custom:file-download h-5 w-5 shrink-0" />
            </div>
          </BaseButton>
          <a ref="sampleTemplateLink" type="hidden" href="/templates/Sample_Template.csv" download="Sample_Template.csv" />
        </div>
      </div>
      <div class="flex flex-1 items-center justify-between gap-2 border-1 border-gray-200/20 rounded-3 bg-white bg-opacity-20 pa-4">
        <div>
          <p class="text-sm font-semibold">
            {{ t('account_verification.import_template') }}
          </p>
          <p class="text-sm">
            {{ t('account_verification.import_template_hint') }}
          </p>
        </div>
        <div>
          <BaseButton color="secondary" size="md" @click="handleDownloadFile(emptyTemplateLink)">
            <div class="flex items-center gap-2">
              <p class="font-semibold">
                {{ t('account_verification.download') }}
              </p>
              <div class="i-custom:file-download h-5 w-5 shrink-0" />
            </div>
          </BaseButton>
          <a ref="emptyTemplateLink" type="hidden" href="/templates/Empty_Template.csv" download="Empty_Template.csv" />
        </div>
      </div>
    </div>
  </div>
</template>
