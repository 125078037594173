import type { LightStealthRoute } from '@/types'

export const lightstealthGeneralRoutes: LightStealthRoute[] = [
  {
    name: 'service',
    path: '/service',
    permissions: ['view:observations'],
    multiArea: true,
    icon: 'i-custom:service',
  },
  {
    name: 'short-term-parker',
    path: '/short-term-parker',
    permissions: ['view:allUnlocks', 'view:unlock'],
    multiArea: false,
    icon: 'i-custom:short-term-parker',
  },
  {
    name: 'long-term-parker',
    path: '/long-term-parker',
    permissions: ['view:whitelist', 'view:allWhitelist'],
    multiArea: false,
    icon: 'i-custom:long-term-parker',
  },
  {
    name: 'statistics',
    path: '/statistics',
    permissions: ['view:statistics', 'view:coin_checker', 'statistics:AnalyzeOccupancyData'],
    multiArea: true,
    icon: 'i-custom:statistics',
    children: [
      {
        name: 'parking-stays',
        path: '/statistics/parkingStays',
        permissions: ['view:statistics'],
        multiArea: true,
      },
      {
        name: 'payment-count-overview',
        path: '/statistics/paymentCountOverview',
        permissions: ['view:statistics', 'view:coin_checker',
        ],
        multiArea: true,
      },
      {
        name: 'payment-stats',
        path: '/statistics/paymentStats',
        permissions: ['view:statistics'],
        multiArea: true,
      },
      {
        name: 'parker-details',
        path: '/statistics/parkerDetails',
        permissions: ['view:statistics'],
        multiArea: true,
      },
      {
        name: 'parking-violations',
        path: '/statistics/parkingViolations',
        permissions: ['statistics:AnalyzeEnforcement'],
        multiArea: true,
      },
      {
        name: 'unlocks-overview',
        path: '/statistics/unlocksOverview',
        permissions: ['view:allUnlocks'],
        multiArea: true,
      },
      {
        name: 'last-payments',
        path: '/statistics/lastPayments',
        permissions: ['statistics:AnalyzeLastPayment'],
        multiArea: true,
      },
      {
        name: 'occupancy-graph',
        path: '/statistics/occupancyGraph',
        permissions: ['statistics:AnalyzeOccupancyData'],
        multiArea: true,
      },
    ],
  },
  {
    name: 'user-management',
    path: '/user-management',
    permissions: ['view:user'],
    multiArea: false,
    icon: 'i-custom:user-management',
  },
]

export const lightstealthInternalToolsRoutes: LightStealthRoute[] = [
  {
    name: 'investigate',
    path: '/investigate',
    permissions: ['investigation:approve'],
    multiArea: false,
    icon: 'i-custom:investigate',
  },
  {
    name: 'area-details',
    path: '/area-details',
    permissions: ['view:pricing'],
    multiArea: false,
    icon: 'i-custom:area-details',
  },
  {
    name: 'enforcement-exception-job',
    path: '/enforcement-exception-job',
    permissions: ['view:enforcement_exception_job'],
    multiArea: false,
    icon: 'i-custom:enforcement-exception-job',
  },
  {
    name: 'release-enforcement',
    path: '/release-enforcement',
    permissions: ['view:investigations'],
    multiArea: false,
    icon: 'i-custom:release-enforcement',
  },
]

export const lightstealthToolsRoutes: LightStealthRoute[] = [
  {
    name: 'query-demand',
    path: '/query-demand',
    permissions: ['view:demandReference'],
    multiArea: false,
    icon: 'i-custom:query-demand',
  },
  {
    name: 'door-opener',
    path: '/door-opener',
    permissions: ['view:door'],
    multiArea: false,
    icon: 'i-custom:door-opener',
  },
  {
    name: 'parking-guidance',
    path: '/parking-guidance',
    permissions: ['counting:get'],
    multiArea: false,
    icon: 'i-custom:parking-guidance',
  },
  {
    name: 'qr-code',
    path: '/qr-code',
    permissions: ['view:qrcode_configurator'],
    multiArea: false,
    icon: 'i-custom:qr-code',
  },
  {
    name: 'ground-truth',
    path: '/ground-truth',
    permissions: ['view:groundtruth'],
    multiArea: false,
    icon: 'i-custom:ground-truth',
  },
  {
    name: 'apply-discounts',
    path: '/apply-discounts',
    permissions: ['view:appliedDiscount'],
    multiArea: false,
    icon: 'i-custom:apply-discounts',
  },
]
