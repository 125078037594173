<script lang="ts" setup>
import type { Language } from '@/types'
import { userSettings } from '@/stores/userSettings'
import { logLanguageSelect } from '@/utils/logger'

defineOptions({
  name: 'SidebarLanguageSelector',
})

const { t } = useI18n()

const userSettingsStore = userSettings()

const languages = computed(() => {
  return [
    {
      label: t('en'),
      value: 'en',
      icon: 'i-circle-flags:gb',
    },
    {
      label: t('de'),
      value: 'de',
      icon: 'i-circle-flags:de',
    },
    {
      label: t('it'),
      value: 'it',
      icon: 'i-circle-flags:it',
    },
  ]
})
const open = ref(false)

function setLanguage(language: Language) {
  logLanguageSelect(language.value)
  userSettingsStore.setLanguage(language.value)
  open.value = false
}

const selectedLanguage = computed(() => {
  return languages.value.find(language => language.value === userSettingsStore.userSelectedLanguage)
})

const selectableLanguages = computed(() => {
  return languages.value.filter(language => language.value !== userSettingsStore.userSelectedLanguage)
})

const buttonClass = 'w-full flex px-2 py-2 bg-gray-100 text-gray-600 items-center hover:text-gray-900'
</script>

<template>
  <div class="flex flex-col" data-testid="language-selector-wrapper">
    <button :key="selectedLanguage?.value" :class="buttonClass" class="pt-1 font-semibold" data-testid="selected-language-button" @click="open = !open">
      <div :class="selectedLanguage?.icon" class="mr-3 h-6 w-6" data-testid="selected-language-icon" />
      {{ selectedLanguage?.label }}
      <div class="i-lucide:chevron-right ml-auto h-5 w-5 bg-gray-500 transition" :class="{ 'rotate-90': open }" />
    </button>
    <transition
      enter-active-class="transition-all ease-out duration-150"
      enter-from-class="transform h-0"
      enter-to-class="transform h-20"
      leave-active-class="transition-all ease-in duration-150"
      leave-from-class="transform h-20"
      leave-to-class="transform h-0"
    >
      <div v-if="open" id="language-container" class="w-full flex flex-col items-center overflow-hidden p-0" data-testid="language-options">
        <button v-for="language in selectableLanguages" :key="language.value" :class="buttonClass" data-testid="language-option-button" @click="setLanguage(language)">
          <div :class="language.icon" class="mr-3 h-6 w-6" data-testid="language-option-icon" />
          {{ language.label }}
        </button>
      </div>
    </transition>
  </div>
</template>
