<script lang="ts" setup>
import { ListboxGroup, ListboxItem, ListboxItemIndicator, ListboxVirtualizer } from 'radix-vue'
import { getAreaLabel } from '../utils'
import type { AreaFromGetAreas } from '@/types'

const props = withDefaults(
  defineProps<{
    label: string
    multiple: boolean
    disableable?: boolean
    indicatorClass: string
    inactiveIndicatorClass?: string
    minItems?: number
    maxItems?: number
    itemSize?: number
    areas: Array<AreaFromGetAreas>
  }>(),
  {
    disableable: false,
    minItems: 0,
    maxItems: 10,
    itemSize: 32,
  },
)

const disabled = computed(() => props.disableable && !props.multiple)
const minListHeight = computed(() => `${props.itemSize * props.minItems}px`)
const maxListHeight = computed(() => `${props.itemSize * props.maxItems}px`)
</script>

<template>
  <ListboxGroup class="h-full">
    <ListboxGroupLabel class="mb-2 ml-3 flex items-center gap-2 text-xs text-gray-400 font-300 uppercase">
      <p>{{ props.label }}</p>
      <div
        v-if="props.multiple"
        class="rounded-sm bg-gray-200 px-1 py-0.5 text-3 text-gray-500 font-500 leading-none"
      >
        {{ props.areas.length }}
      </div>
    </ListboxGroupLabel>

    <div
      class="virtual-wrapper overflow-auto scrollbar-thumb-color-grayCool-300 scrollbar-track-color-transparent scrollbar-radius-2 scrollbar-thumb-radius-4 scrollbar-track-radius-4 scrollbar-w-8px scrollbar scrollbar-rounded"
    >
      <ListboxVirtualizer v-slot="{ option }" :estimate-size="props.itemSize" :options="props.areas" :text-content="(opt) => getAreaLabel(opt)">
        <ListboxItem
          :value="option"
          class="group relative w-full flex cursor-pointer select-none items-center justify-start whitespace-nowrap px-3 outline-none transition-all data-[disabled]:cursor-default"
          :disabled="disabled"
        >
          <div v-if="props.inactiveIndicatorClass" class="absolute text-gray-300" :class="props.inactiveIndicatorClass" />
          <ListboxItemIndicator class="absolute text-ppGreenDeep" :class="props.indicatorClass" />
          <p class="ml-5 text-gray-900 leading-tight group-data-[disabled]:opacity-50" :class="{ 'group-data-[highlighted]:font-medium': !disabled }">
            {{ getAreaLabel(option) }}
          </p>
        </ListboxItem>
      </ListboxVirtualizer>
    </div>
  </ListboxGroup>
</template>

<style scoped>
.virtual-wrapper {
  min-height: v-bind(minListHeight);
  max-height: v-bind(maxListHeight);
}
</style>
