<script lang="ts" setup>
defineOptions({
  name: 'FormPriceGroupOption',
})

const props = defineProps<PriceGroupOptionProps>()
interface PriceGroupOptionProps {
  /**
   * The name of the input, it's needed for vee-validate to work properly
   */
  name: string
  /**
   * The value of the checkbox
   */
  value: string
  /**
   * label to display
   */
  label: string
  /**
   * Description to display
   */
  description: string
}
</script>

<template>
  <div class="rounded-lg bg-white ring-1 ring-gray-200 divide-y divide-gray-200">
    <div class="pb-2 pl-4 pr-5 pt-4">
      <FormCheckbox :name="props.name" :value="props.value">
        <p class="text-left font-600">
          {{ props.label }}
        </p>
      </FormCheckbox>
    </div>
    <p class="px-4 pb-4 pt-2 text-sm text-gray-600 font-400">
      {{ props.description }}
    </p>
  </div>
</template>
