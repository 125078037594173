<script lang="ts" setup>
import { toast } from 'vue-sonner'
import { useGetVerificationSummary } from '@/composables/openapi/user/useGetVerificationSummary'
import { formatAreaName } from '@/utils/areas'

defineOptions({
  name: 'AccountVerificationAreaCards',
})

const { t } = useI18n()
const router = useRouter()

const { data: verificationSummaryArray, suspense: getVerificationSummarySuspense, error: getVerificationSummaryError } = useGetVerificationSummary()
await getVerificationSummarySuspense()

watch(getVerificationSummaryError, () => {
  toast.error(t('error_fallback'))
})

function navigateToSummary(areaId: string) {
  router.push(`/account-verification/${areaId}`)
}
</script>

<template>
  <div class="flex flex-wrap gap-2 lg:gap-6">
    <div
      v-for="area in verificationSummaryArray" :key="area.areaEntityId"
      class="w-full rounded-xl bg-gray-100 p-1 pt-2 2xl:w-[calc(33.33333%-1rem)] lg:w-[calc(50%-0.75rem)]"
    >
      <div class="w-full flex flex-col gap-2">
        <span class="flex items-center gap-1 px-4 pt-2 text-4.5 color-gray-900 leading-7">
          <div class="i-custom:area h-6 w-6" />
          {{ formatAreaName(area) }}
        </span>
        <div class="w-full flex flex-col justify-between gap-4 rounded-md bg-white p-4 lg:p-2 xl:p-4">
          <div class="w-full flex justify-between gap-2">
            <div class="min-w-35 w-full flex flex-col b-1 rounded-2 p-2">
              <span class="text-2xl text-gray-900">
                {{ area.totalUploadedCodesCount }}
              </span>
              <span class="text-nowrap text-xs text-gray-600 font-400">{{ t('account_verification.imported_accounts') }}</span>
            </div>
            <div class="min-w-35 w-full flex flex-col b-1 rounded-2 p-2">
              <span class="text-2xl text-gray-900">
                {{ area.totalVerifiedCodesCount }}
              </span>
              <span class="text-nowrap text-xs text-gray-600 font-400">{{ t('account_verification.verified_accounts') }}</span>
            </div>
          </div>
          <BaseButton color="outline-gray" class="w-fit" @click="navigateToSummary(area.areaEntityId)">
            <span class="text-3.5 text-gray-700 font-600">{{ t('view_details') }}</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
