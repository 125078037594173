<script lang="ts" setup>
defineOptions({
  name: 'WizardNav',
})

const props = defineProps<{
  nextStepName?: string
  nextStepIndex?: number
  previousStepIndex: number
  nextStepDisabled: boolean
  loading: boolean
}>()

const emit = defineEmits<{
  navigateToStep: [value: number]
  submitForm: []
}>()

const { t } = useI18n()

function navigateToStep(index: number | undefined) {
  if (index === undefined)
    return
  emit('navigateToStep', index)
}
</script>

<template>
  <nav class="fixed bottom-0 w-full bg-gray-100 p-3">
    <div class="flex-end mx-auto flex items-end justify-between container md:items-center">
      <BaseButton :disabled="previousStepIndex < 0" tag="button" class="flex items-center gap-2 font-600" size="md" color="outline-gray" @click="navigateToStep(previousStepIndex)">
        <div class="i-lucide:arrow-left" />
        {{ t('back') }}
      </BaseButton>
      <div class="flex flex-col items-end justify-between gap-2 md:flex-row md:items-center md:gap-8">
        <div v-if="props.nextStepName" class="flex gap-1 text-right">
          <p class="text-xs">
            {{ t('next_step') }}
          </p>
          <p class="text-xs text-gray-500">
            {{ props.nextStepName }}
          </p>
        </div>
        <BaseButton v-if="nextStepIndex" class="flex items-center gap-2 font-600" :disabled="props.nextStepDisabled || loading" tag="button" size="md" color="primary" @click="navigateToStep(nextStepIndex)">
          {{ t('continue') }}
          <div :class="loading ? 'i-tabler-loader-2 animate-spin bg-gray-300 duration-700 ease-in-out' : 'i-lucide:arrow-right'" />
        </BaseButton>
        <BaseButton v-else tag="button" size="md" class="font-600" color="primary" @click="emit('submitForm')">
          {{ t('submit') }}
        </BaseButton>
      </div>
    </div>
  </nav>
</template>
