<script lang="ts" setup generic="DataType">
import type { Header } from '@tanstack/vue-table'
import { FlexRender } from '@tanstack/vue-table'

defineOptions({
  name: 'TableHead',
})

const props = withDefaults(defineProps<{
  headerInstance: Header<DataType, unknown>[]
  showActions?: boolean
  sortable?: boolean
  multiSelect?: {
    enable: boolean
    isSomeSelected: boolean
    isAllSelected: boolean
  }
}>(), {
  showActions: true,
})

const emits = defineEmits<{
  onToggleSelectAll: [value: boolean]
}>()

const selectAll = ref(false)

function onToggleSelectAll(value: boolean) {
  emits('onToggleSelectAll', value)
}

const multiSelect = toRef(props, 'multiSelect')

watchDeep(multiSelect, (multiSelect) => {
  selectAll.value = multiSelect?.isAllSelected ?? false
})

const { t } = useI18n()
</script>

<template>
  <thead class="bg-gray-25">
    <tr class="px-2 py-1 sm:px-6 sm:py-3">
      <BaseTableHeadCell v-if="props.multiSelect?.enable">
        <BaseTableCheckbox :value="selectAll" :partial-selection="multiSelect?.isSomeSelected" @on-change="onToggleSelectAll" />
      </BaseTableHeadCell>
      <BaseTableHeadCell
        v-for="(header) in props.headerInstance"
        :key="header.id"
        @click="sortable ? header.column.getToggleSortingHandler()?.($event) : undefined"
      >
        <div v-if="!header.isPlaceholder" class="w-full flex">
          <FlexRender :render="header.column.columnDef.header" :props="header.getContext()" />
          <BaseTableSortIndicator v-if="sortable" :is-sorted="header.column.getIsSorted()" />
        </div>
      </BaseTableHeadCell>
      <slot name="additionalColumns" />
      <BaseTableHeadCell v-if="props.showActions">
        {{ t('actions') }}
      </BaseTableHeadCell>
    </tr>
  </thead>
</template>
