import type { ComputedRef, MaybeRef } from 'vue'
import type { VerificationCodeDetailsDto } from '@peter-park/user'
import { useGetVerificationCodesByArea as _baseRequest } from '../useGetVerificationCodesByArea'

export function useGetVerificationCodesByArea(areaEntityId: MaybeRef<string>, isEnabled: MaybeRef<boolean> | ComputedRef<boolean> = true) {
  const searchQuery = ref('')
  const pageSize = ref(50)
  const startCursor = ref<string>()

  const payload = computed(() => ({
    areaEntityId: unref(areaEntityId),
    searchQuery: unref(searchQuery),
    cursor: unref(startCursor),
    limit: unref(pageSize),
  }))

  const { data, isFetching, refetch, error } = _baseRequest(payload, isEnabled)

  const codesMap = shallowRef(new Map<string, VerificationCodeDetailsDto>())
  const verificationCodes = computed(() => {
    if (!error.value)
      return Array.from(codesMap.value.values())
    return []
  })

  const hasNextPage = computed(() => data?.value?.hasNextPage ?? false)
  const hasPreviousPage = computed(() => data?.value?.hasPreviousPage ?? false)

  function storeCodes(codes: VerificationCodeDetailsDto[]) {
    for (const code of codes) {
      codesMap.value.set(code.id, code)
    }
    triggerRef(codesMap)
  }

  function resetCodes() {
    codesMap.value.clear()
    triggerRef(codesMap)
  }

  function loadNextPage() {
    if (data.value)
      startCursor.value = data.value.endCursor ?? undefined
  }

  watch(data, (newData) => {
    if (!error.value)
      storeCodes(newData?.data ?? [])
  })

  watchDebounced(searchQuery, () => {
    startCursor.value = undefined
    resetCodes()
    refetch()
  }, { debounce: 500 })

  watchDebounced(startCursor, () => {
    refetch()
  }, { debounce: 500 })

  return {
    error,
    hasNextPage,
    hasPreviousPage,
    isFetching,
    loadNextPage,
    pageSize,
    resetCodes,
    searchQuery,
    startCursor,
    verificationCodes,
  }
}
