import { defineStore } from 'pinia'
import { useI18nLocales } from '@peter-park/vue-common-utils'
import type { RemovableRef } from '@vueuse/core'
import type { Ref } from 'vue'
import type { AreaFromGetAreas, Permission } from '@/types'

export const userSettings = defineStore('userSettings', (): {
  availableLocales: string[]
  navigatorLanguage: Ref<string | undefined>
  userSelectedLanguage: RemovableRef<string>
  permissions: Ref<Permission[]>
  setPermissions: (newPermissions: Permission[]) => void
  setLanguage: (locale: string) => void

  selectedAreas: Ref<AreaFromGetAreas[] | AreaFromGetAreas | undefined>
  setSelectedAreas: (areas: AreaFromGetAreas[] | AreaFromGetAreas | undefined) => void
} => {
  const { availableLocales, navigatorLanguage, userSelectedLanguage, setLanguage } = useI18nLocales('de')

  const permissions = ref<Permission[]>([])
  function setPermissions(newPermissions: Permission[]) {
    permissions.value = newPermissions
  }

  const selectedAreas: Ref<AreaFromGetAreas[] | AreaFromGetAreas | undefined> = ref<AreaFromGetAreas[] | AreaFromGetAreas | undefined>(undefined)

  function setSelectedAreas(areas: AreaFromGetAreas[] | AreaFromGetAreas | undefined) {
    selectedAreas.value = areas
  }

  return {
    availableLocales,
    navigatorLanguage,
    userSelectedLanguage,
    permissions,
    selectedAreas,
    setLanguage,
    setPermissions,
    setSelectedAreas,
  }
})
