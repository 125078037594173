<script setup lang="ts">
import type { VerificationsGenericErrorDto } from '@peter-park/user'

defineOptions({
  name: 'AccountVerificationReview',
})

const props = defineProps<{
  loading: boolean
  hasBeenUploaded: boolean
  errorResponse?: VerificationsGenericErrorDto
}>()

const errors = computed(() => {
  if (props.loading)
    return []
  return props.errorResponse?.params || []
})

const { t } = useI18n()
</script>

<template>
  <FormSection
    :title="t('account_verification.steps.import_accounts')"
    :description="t('use_csv_template')"
  >
    <div class="flex flex-col gap-4">
      <slot />
      <div v-if="props.loading" class="flex gap-4 overflow-auto b-1 b-gray-200 rounded-xl px-5 py-5 md:overflow-unset">
        <div class="i-svg-spinners:180-ring-with-bg h-5 w-5 bg-pp-green-deep" />
        <span class="text-sm leading-5">{{ t('verifying_data.loading') }}</span>
      </div>
      <div v-else-if="props.errorResponse" class="flex flex-col gap-1 overflow-auto b-1 b-red-200 rounded-xl bg-red-25 px-5 py-5 md:overflow-unset">
        <span class="text-sm text-red-700 leading-5">{{ t('verifying_data.error.title') }}</span>
        <span class="text-sm text-red-600 leading-5">{{ t('verifying_data.error.details') }}</span>
      </div>
      <div v-else-if="props.hasBeenUploaded" class="flex gap-4 overflow-auto b-1 b-green-200 rounded-xl bg-green-25 px-5 py-5 md:overflow-unset">
        <div class="i-custom:success-circle mt-1 h-5 w-5" />
        <div class="flex flex-col gap-1">
          <span class="text-sm text-green-700 leading-5">{{ t('verifying_data.success.title') }}</span>
          <span class="text-sm text-green-600 leading-5">{{ t('verifying_data.success.details') }}</span>
        </div>
      </div>
    </div>
  </FormSection>
  <div v-show="props.errorResponse" class="flex flex-col gap-4">
    <div v-for="(error, idx) in errors" :key="idx">
      <AccountVerificationFileUploadError :error="error" />
    </div>
  </div>
</template>
