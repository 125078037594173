<script lang="ts" setup>
defineOptions({
  name: 'SearchInput',
})

withDefaults(defineProps<{
  id?: string
}>(), {
  id: 'ui',
})

const modelValue = defineModel<string>()

const { t } = useI18n()

function onHandleUpdateModelValue(event: Event) {
  modelValue.value = (event.target as HTMLInputElement).value
}
</script>

<template>
  <div class="relative mt-2 flex items-center">
    <div class="absolute left-2">
      <div class="i-custom:search h-5 min-w-5 text-gray-500" />
    </div>
    <input
      :data-testid="`${id}-search-input`"
      type="text"
      class="block w-full rounded-md px-3.5 py-2 pl-9 text-gray-900 ring-1 ring-gray-200 placeholder:text-gray-500"
      :placeholder="t('search_anything')"
      @input="onHandleUpdateModelValue"
    >
  </div>
</template>
