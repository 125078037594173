<script setup lang="ts">
defineOptions({
  name: 'AppHeaderAreaSelectorVisualizerItem',
})

const props = withDefaults(defineProps<{
  /**
   * The label to display
   */
  label: string
  /**
   * The id of the item, so we can emit the remove event with the id
   */
  id: string
  /**
   * If the item is disabled. EX: when hovering on other elements
   */
  disabled?: boolean
  /**
   * If the listbox allows multiple selection, enable the remove button, if not do not show the remove button
   */
  multiple?: boolean
}>(), {
  disabled: false,
  multiple: true,
})

const emit = defineEmits<{
  /**
   * Emit when the user clicks on the cross button
   */
  remove: [value: string]
}>()
</script>

<template>
  <div
    class="h-8 flex grow items-center justify-between gap-1.5 overflow-hidden border-1 border-white/40 rounded-lg bg-white/20 px-3 py-2 text-sm text-white leading-none"
    :class="
      [
        { 'opacity-40 cursor-pointer': props.disabled },
      ]
    "
  >
    <p
      class="flex-initial overflow-hidden text-ellipsis whitespace-nowrap font-600 leading-none"
    >
      {{ props.label }}
    </p>
    <button v-if="props.multiple" class="i-lucide:circle-x h-4 w-4 flex-none" @click.stop="emit('remove', props.id)" />
  </div>
</template>
