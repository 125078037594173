interface AreaShim {
  locality?: string
  name?: string
  areaLocality?: string
  areaEntityName?: string
}

/**
 * Returns the formatted area name built from the locality and the name
 * @param area the area to format
 * @param separator the separator to use between the locality and the name
 * @returns the formatted area name
 * @remarks this function use a shim to be able to accept different types of areas
 * @example ```ts
   formatAreaName({ locality: 'Paris', name: 'Eiffel Tower' }) // 'Paris - Eiffel Tower'
   formatAreaName({ locality: 'Paris' }) // 'Paris'
   formatAreaName({ name: 'Eiffel Tower' }) // 'Eiffel Tower'
   formatAreaName({ areaLocality: 'Paris', name: 'Eiffel Tower' }) // 'Paris - Eiffel Tower'
   formatAreaName({ areaLocality: 'Paris', areaEntityName: 'Eiffel Tower' }) // 'Paris - Eiffel Tower'
 ```
 */
export function formatAreaName<T extends AreaShim>(area: T, separator = ' - '): string {
  const locality = area.locality || area.areaLocality
  const name = area.name || area.areaEntityName
  return [locality, name].filter(Boolean).join(separator)
}
