<script setup lang="ts">
import type { Option } from './types'

const props = defineProps<{
  option: Option
}>()
</script>

<template>
  <ComboboxItem
    class="relative min-h-7 flex select-none items-center gap-2 px-2 data-[disabled]:pointer-events-none data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900"
    :value="props.option"
  >
    <span class="flex items-center gap-2 p-2">
      <div v-if="props.option.icon" :class="props.option.icon" class="h-4 w-4" />
      {{ props.option.name }}
    </span>
  </ComboboxItem>
</template>
