<script setup lang="ts">
import { useField } from 'vee-validate'
import { useDropZone } from '@vueuse/core'
import { imageMeta } from 'image-meta'
import type { ImageMeta } from 'image-meta'
import { bytesToReadable } from '@/types'

defineOptions({
  name: 'FormInputFile',
})

const props = withDefaults(defineProps<{
  /**
   * name of the input, required to bind it to vee-validate
   */
  name: string
  /**
   * Title of the component, will render in a heading
   */
  title?: string
  /**
   * Description of the component, will render in a paragraph
   */
  description?: string
  /**
   * Array of accepted mime types, it will be used to filter accepted files in the file selector dialog and also render as a hint for the user
   * @default ['image/png', 'image/jpeg', 'image/gif']
   */
  dataTypes?: Array<string>
  /**
   * Maximum file size in bytes, it will be used as a hint for the user
   * @default 10e6
   */
  limit?: number
  /**
   * Allow multiple files to be uploaded
   * @default false
   */
  multiple?: boolean
}>(), {
  dataTypes: () => ['image/png', 'image/jpeg', 'image/gif'],
  limit: 10e6,
  multiple: false,
})

const { t } = useI18n()

const { name, dataTypes, limit } = toRefs(props)

const fileMeta = ref<{
  name: string
  size: string
}>()
const imageData = ref<ImageMeta>()

const {
  value,
  errorMessage,
  handleBlur,
  handleChange,
  handleReset,
  meta,
} = useField(name, undefined, { validateOnMount: true })

const dropZoneRef = ref<HTMLDivElement>()

const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: (files) => {
    if (files && files.length > 0) {
      if (props.multiple)
        handleChange(files)
      else
        handleChange(files[0])
    }
  },
})

function updateFileMeta(file: File) {
  fileMeta.value = {
    name: file.name,
    size: bytesToReadable(file.size),
  }
}

function getExtension(extensionOrMimeType: string) {
  if (extensionOrMimeType.startsWith('.'))
    return extensionOrMimeType
  return `.${extensionOrMimeType.split('/')[1]}`
}

async function updateImageMeta(file: File) {
  const buffer = await file.arrayBuffer()
  imageData.value = imageMeta(new Uint8Array(buffer))
}

const isImage = computed(() => dataTypes.value.some(type => type.startsWith('image/')))
const readableLimit = computed(() => bytesToReadable(limit.value))
const readableTypes = computed(() => dataTypes.value.map(type => getExtension(type)).join(', '))
const hasUploadedFile = computed(() => value.value instanceof File && errorMessage.value === undefined)

const borderClasses = computed(() => {
  if (isOverDropZone.value)
    return 'border-ppGreenDark/25'
  return 'border-gray-900/25'
})

function handleClick(event: Event) {
  // Workaround to enable input field file-upload with the same name multiple times
  (event.target as HTMLInputElement).value = ''
}

watchDeep(value, (file) => {
  if (file instanceof File) {
    updateFileMeta(file)
    if (isImage.value)
      updateImageMeta(file)
  }
})

const isFileValid = computed(() => {
  return meta.dirty && meta.valid
})

// watch(isFileValid, (isValid) => {
//   if (!isValid) {
//     value.value = null
//   }
// })
</script>

<template>
  <div class="flex flex-col items-start gap-4 rounded rounded-lg bg-gray-100 text-slate-600">
    <div v-if="props.title || props.description" class="px-2">
      <h2 v-if="props.title" class="text-gray-900 font-medium">
        {{ props.title }}
      </h2>
      <p v-if="props.description" class="text-sm">
        {{ props.description }}
      </p>
    </div>
    <div v-if="!isFileValid && meta.dirty" class="w-full border border-red-700 rounded-lg bg-red-50/50 px-6 py-4 text-sm">
      <p class="text-red-700 font-medium">
        {{ t('upload_error') }}
      </p>
      <p v-if="errorMessage" class="text-red-600">
        {{ errorMessage }}
      </p>
    </div>
    <label v-show="!hasUploadedFile" ref="dropZoneRef" :for="props.name" class="w-full flex cursor-pointer justify-center border rounded-lg bg-white px-6 py-4" :class="borderClasses">
      <div class="text-center">
        <div class="h-10 w-10 inline-flex items-center justify-center border-4 border-gray-50 rounded rounded-full bg-gray-100">
          <i class="i-lucide:cloud-upload inline-block h-5 w-5" aria-hidden="true" :class="isOverDropZone ? 'text-slate-600' : 'text-slate-300'" />
        </div>
        <div class="mt-4 flex text-sm leading-6">
          <span class="f font-semibold hover:text-slate-500">{{ t('upload_a_file') }}</span>
          <input :id="props.name" :name="props.name" type="file" class="sr-only" :accept="dataTypes.join(',')" :multiple @click="handleClick" @change="handleChange" @blur="handleBlur">
          <p class="pl-1">
            {{ t('or_drag_and_drop') }}
          </p>
        </div>
        <p class="text-xs leading-5">
          {{ t('file_limitations', { types: readableTypes, size: readableLimit }) }}
        </p>
      </div>
    </label>
    <div v-if="hasUploadedFile" class="w-full flex items-start gap-6 border border-ppGreenDark rounded rounded-lg bg-white px-6 py-4">
      <i class="i-lucide:file h-6 w-6" />
      <div class="flex flex-col text-sm">
        <template v-if="fileMeta">
          <p class="text-slate-700 font-medium">
            {{ fileMeta.name }}
          </p>
          <p class="text-slate-600">
            {{ fileMeta.size }}
          </p>
        </template>
        <p v-if="isImage && imageData" class="text-slate-600">
          {{ t('image_dimensions', { width: imageData.width, height: imageData.height }) }}
        </p>
      </div>
      <button
        type="reset" class="group ml-auto mr-0 h-5 w-5 flex items-center justify-center rounded rounded-full p-1 text-white transition-all hover:bg-gray-600"
        :class="isFileValid ? 'bg-ppGreenDark' : 'bg-gray-500'"
        @click="handleReset()"
      >
        <i class="group-hover:i-lucide:x transition-all" :class="isFileValid ? 'i-lucide:check' : 'i-lucide:x'" />
      </button>
    </div>
  </div>
</template>
