import { Configuration, DefaultApi } from '@peter-park/group'
import { useGenericOpenApiClient } from '../useGenericOpenApiClient'

export function useOpenApiClient() {
  return useGenericOpenApiClient<Configuration, DefaultApi>(
    'group',
    Configuration,
    DefaultApi,
  )
}
