<script lang="ts" setup>
import type { Permission } from '@/types'

import { lightstealthGeneralRoutes, lightstealthInternalToolsRoutes, lightstealthToolsRoutes } from '@/utils/lightstealthRouteConfig'
import { userSettings } from '@/stores/userSettings'

defineOptions({
  name: 'Navigator',
})

const { t } = useI18n()

const userSettingsStore = userSettings()
function isPermitted(permissions: Permission[]) {
  return userSettingsStore.permissions.some(permission => permissions.includes(permission))
}

const lightstealthGeneralNavigation = computed(() => {
  return lightstealthGeneralRoutes.map((item) => {
    return {
      name: item.children ? t(`sidebar.${item.name.replace(/-/g, '_')}.${item.name.replace(/-/g, '_')}`) : t(`sidebar.${item.name.replace(/-/g, '_')}`),
      locationName: item.name,
      icon: item.icon,
      permitted: isPermitted(item.permissions),
      children: item.children?.map((child) => {
        return {
          name: t(`sidebar.${item.name}.${child.name.replace(/-/g, '_')}`),
          locationName: child.name,
          icon: child.icon,
          permitted: isPermitted(child.permissions),
        }
      }),
    }
  })
})

const lightstealthInternalToolsNavigation = computed(() => {
  return lightstealthInternalToolsRoutes.map((item) => {
    return {
      name: t(`sidebar.${item.name.replace(/-/g, '_')}`),
      locationName: item.name,
      icon: item.icon,
      permitted: isPermitted(item.permissions),
      children: item.children?.map((child) => {
        return {
          name: t(`sidebar.${item.name}.${child.name.replace(/-/g, '_')}`),
          locationName: child.name,
          icon: child.icon,
          permitted: isPermitted(item.permissions),
        }
      }),
    }
  })
})

const lightstealthToolsNavigation = computed(() => {
  return lightstealthToolsRoutes.map((item) => {
    return {
      name: t(`sidebar.${item.name.replace(/-/g, '_')}`),
      locationName: item.name,
      icon: item.icon,
      permitted: isPermitted(item.permissions),
      children: item.children?.map((child) => {
        return {
          name: t(`sidebar.${item.name}.${child.name.replace(/-/g, '_')}`),
          locationName: child.name,
          icon: child.icon,
          permitted: isPermitted(item.permissions),
        }
      }),
    }
  })
})

const generalNavigation = computed(() => {
  return [
    {
      name: t('sidebar.dashboard'),
      locationName: 'home',
      icon: 'i-custom:dashboard',
      permitted: true,
    },
    ...lightstealthGeneralNavigation.value,
  ]
})

const navigation = computed(() => {
  return [
    {
      items: generalNavigation.value,
    },
    {
      name: t('sidebar.internal_tools'),
      items: lightstealthInternalToolsNavigation.value,
    },
    {
      name: t('sidebar.tools'),
      items: [
        ...lightstealthToolsNavigation.value,
        {
          name: t('sidebar.account_verification'),
          locationName: 'account-verification',
          icon: 'i-custom:users-check',
          permitted: isPermitted(['view:account_verification']),
        },
      ],
    },
  ]
})
</script>

<template>
  <!-- Static sidebar for desktop -->
  <div class="inset-y-0 z-20 ml-auto w-78 flex flex-col md:fixed md:ml-unset">
    <div class="h-26 shrink-0 items-center justify-between px-3 pb-10 pl pl-5 pt-8 hidden md:flex">
      <router-link to="/">
        <img src="@/assets/logo.svg" alt="Peter Park">
      </router-link>
    </div>
    <AppSidebar :navigation="navigation" />
  </div>
</template>
