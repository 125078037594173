import { z } from 'zod'

/**
 * returns a `z.custom<File>` validation that checks if the file is of the correct type and size
 * @param mimeTypes - an array of mime types to accept; defaults to an empty array (accepts all types)
 * @param maxSize - the maximum size of the file in bytes; defaults to -1 (no limit)
 */
export function fileValidation(mimeTypes: Array<string> = [], maxSize = -1, required = false) {
  return z.custom<File>().nullable().refine((data) => {
    if (!data)
      return !required // if file is required return false
    if (data.size > maxSize)
      return false
    if (!mimeTypes.includes(data.type))
      return false
    return true
  })
}

export function bytesToReadable(_bytes: number) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let unitIndex = 0
  let bytes = _bytes
  while (bytes > 1024) {
    bytes /= 1024
    unitIndex++
  }
  return `${bytes.toFixed(2)} ${units[unitIndex]}`
}
