import { Configuration, DefaultApi } from '@peter-park/area-tree'
import { useGenericOpenApiClient } from '../useGenericOpenApiClient'

export function useOpenApiClient() {
  return useGenericOpenApiClient<Configuration, DefaultApi>(
    'area-tree',
    Configuration,
    DefaultApi,
  )
}
