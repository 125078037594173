<script setup lang="ts" generic="DataType">
defineOptions({
  name: 'BaseTableCheckbox',
})

const props = defineProps<{
  value: boolean
  partialSelection?: boolean
}>()

const emits = defineEmits<{
  onChange: [value: boolean]
}>()

function onChange(value: boolean) {
  emits('onChange', value)
}
</script>

<template>
  <button type="button" class="flex items-center justify-between bg-transparent" @click="onChange(!props.value)">
    <div class="m-0 min-h-5 min-w-5 flex items-center justify-center rounded-md bg-white p-0 ring-1 ring-gray-300 ring-inset transition-all" :class="{ 'ring-ppGreenDeep': props.value }">
      <div
        class="h-4 w-4 opacity-0 transition-opacity"
        :class="{
          'opacity-100 i-custom:checkbox-base text-ppGreenDeep': props.value,
          'opacity-100 i-dashicons:minus text-gray-400': props.partialSelection,
        }"
      />
    </div>
  </button>
</template>
