<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue'
import { storeToRefs } from 'pinia'
import { useIframeMessages } from '@/composables/useIframeMessages'
import { userSettings } from '@/stores/userSettings'
import { authentication } from '@/stores/authentication'
import type { LightStealthPath } from '@/types'
import { useGetAreas } from '@/composables/useGetAreas'

defineOptions({
  name: 'AppIframe',
})

const props = defineProps<{
  path: LightStealthPath
}>()

const { t } = useI18n()

const iframe = ref<HTMLIFrameElement | null>(null)

const { userSelectedLanguage, selectedAreas } = storeToRefs(userSettings())
const { accessToken } = storeToRefs(authentication())

const { isLoading: isAreasLoading, areas } = useGetAreas(false)

const { user } = useAuth0()
const { ready, sendSetAllAreasMessage, sendSetSelectedAreasMessage, sendSetLocaleMessage, sendAuth0TokensMessage } = useIframeMessages(iframe)

const loading = ref(true)
const loadingError = ref(false)

watch([ready, accessToken, user], () => {
  if (ready.value) {
    sendAuth0TokensMessage(accessToken, user)
    loading.value = false
    loadingError.value = false
  }
  else { loading.value = true }
})
watch(ready, () => {
  if (ready.value) {
    setTimeout(() => {
      iframe.value?.blur()
    }, 1000)
  }
}, { immediate: true })

onMounted(() => {
  // After 10 second show an error if it is still loading
  setTimeout(() => {
    if (loading.value) {
      console.warn('Lightstealth iframe did not load in time.')
      loadingError.value = true
    }
  }, 10 * 1000)
})

watchDeep([ready, areas], () => {
  sendSetAllAreasMessage(areas)
}, { immediate: true })

watchDeep([ready, selectedAreas], () => {
  sendSetSelectedAreasMessage(selectedAreas)
}, { immediate: true })

watch([ready, userSelectedLanguage], () => {
  sendSetLocaleMessage(userSelectedLanguage)
}, { immediate: true })

const src = computed(() => {
  const uniqueParam = `nocache=${crypto.randomUUID()}`
  return `${import.meta.env.VITE_LIGHTSTEALTH_URL}${props.path}?embedded=true&${uniqueParam}`
})
</script>

<template>
  <div v-if="!loadingError" class="mx-4 mt-2 block flex items-center gap-2 rounded-xl bg-amber-300 p-2 text-gray-600 md:hidden">
    <div class="i-lucide:circle-alert h-12 w-12 bg-gray-600 px-5" />
    {{ t('mobile_not_supported') }}
  </div>
  <div class="h-full">
    <div v-if="loadingError" class="mx-auto w-md flex items-center justify-center justify-center gap-2 p-2 py-2 md:w-xl md:py-6">
      <div class="i-lucide:circle-alert h-12 w-12 bg-red-600 px-5" />
      {{ t('lightstealth_not_reachable') }}
    </div>
    <div v-else-if="loading || isAreasLoading" class="i-svg-spinners:180-ring-with-bg mx-auto mt-30 h-30 w-30 bg-gray-300" />
    <iframe v-show="!loading" v-if="!isAreasLoading" ref="iframe" :src="src" class="h-full w-full rounded-inherit" />
  </div>
</template>
