<script setup lang="ts">
defineOptions({
  name: 'NumberPicker',
})

const props = withDefaults(
  defineProps<{
    currentPage: number
    pages: number
    visible?: number
  }>(),
  {
    // This is the number of visible pages in the middle, max elements can be: visible + 1 + 1 + 2x('...') = visible + 4
    visible: 4,
  },
)

const emits = defineEmits<{
  pageChange: [page: number]
}>()

const displayedPages = computed(() => {
  const totalPages = props.pages
  const currentPage = props.currentPage
  const maxVisiblePages = props.visible

  if (totalPages <= maxVisiblePages) {
    return Array.from({ length: totalPages }, (_, i) => i + 1)
  }

  const pages = []
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2))
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1)

  if (startPage > 1) {
    pages.push(1)
    if (startPage > 2) {
      pages.push(-1) // value of ...
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i)
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pages.push(-1) // value of ...
    }
    pages.push(totalPages)
  }

  return pages
})
</script>

<template>
  <div class="flex gap-1 sm:gap-2">
    <button
      v-for="page in displayedPages"
      :key="page"
      class="mx-auto h-10 min-w-fit w-5 flex items-center justify-center rounded-2 bg-transparent lg:w-10"
      :class="{
        'bg-white': page === currentPage,
        'pointer-events-none': page === -1 }"
      @click="page !== -1 ? emits('pageChange', page - 1) : undefined"
    >
      <span class="flex items-center gap-2" :class="{ 'font-700': page === currentPage }">
        {{ page !== -1 ? page : '...' }}
      </span>
    </button>
  </div>
</template>
