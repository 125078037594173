<script lang="ts" setup>
import type { Navigation } from '@/types'
import { logNavigation } from '@/utils/logger'

defineOptions({
  name: 'SidebarDisclosure',
})

const props = defineProps<{
  navigation: Navigation
}>()

const route = useRoute()

const open = ref(false)

function onLinkClick() {
  logNavigation(props.navigation.name)
}
const active = computed(() => {
  return props.navigation.children?.some(subItem => subItem.locationName === route.name)
})
</script>

<template>
  <CollapsibleRoot v-model:open="open" as="div">
    <CollapsibleTrigger
      class="w-full flex items-center gap-x-3 bg-gray-100 p-2 font-semibold leading-6 transition hover:text-gray-800"
      :class="[open || active ? 'text-gray-800' : 'text-gray-primary']"
    >
      <div class="h-6 w-6 shrink-0" :class="props.navigation.icon" aria-hidden="true" />
      {{ props.navigation.name }}

      <div class="i-lucide:chevron-right ml-auto h-5 w-5 shrink-0 transition" :class="[open ? 'rotate-90 text-gray-800' : 'text-gray-primary']" aria-hidden="true" />
    </CollapsibleTrigger>
    <CollapsibleContent as="ul" class="CollapsibleContent mt-1 pl-4">
      <li
        v-for="subItem in props.navigation.children" :key="subItem.name" :class="[
          route.name === subItem.locationName ? 'bg-green-500 rounded-r-md' : 'text-gray-primary hover:text-gray-800',
        ]"
      >
        <!-- 44px -->
        <AppSidebarLink v-if="subItem.permitted" :name="subItem.name" :icon="subItem.icon" :location-name="subItem.locationName" child @click="onLinkClick" />
      </li>
    </CollapsibleContent>
  </CollapsibleRoot>
</template>
