<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps<{
  isSorted: string | boolean
}>()

const iconClass = computed(() => {
  if (props.isSorted === 'asc')
    return 'self-middle i-heroicons:chevron-up-20-solid ml-2'
  else if (props.isSorted === 'desc')
    return 'self-middle i-heroicons:chevron-down-20-solid ml-2'
  else
    return 'self-middle i-heroicons:chevron-up-down-20-solid ml-2'
})
</script>

<template>
  <div class="self-center">
    <div :class="iconClass" />
  </div>
</template>
