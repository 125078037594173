import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef } from 'vue'
import { useOpenApiClient } from './useGroupOpenApiClient'
import type { Group } from '@/types'

export function useSearchTicketGroupsByArea(areaEntityId: MaybeRef<string>, enabled: MaybeRef<boolean> = true) {
  const { apiInstance } = useOpenApiClient()
  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
  } = useQuery({
    queryKey: ['searchTicketGroupsByArea', areaEntityId],
    queryFn: () => apiInstance.value.groupControllerSearchTicketGroups({
      searchTicketGroupsDto: {
        areaEntityIds: [unref(areaEntityId)],
      },
    }),
    enabled,
    networkMode: 'always',
  })

  const groups = computed((): Group[] => {
    return data.value?.map((group) => {
      return {
        id: group.id ?? '',
        value: group.id ?? '',
        label: group.name,
        description: group.description ?? '',
      }
    }) ?? []
  })

  return {
    isLoading,
    isError,
    isSuccess,
    data,
    error,
    groups,
  }
}
