import { useMutation } from '@tanstack/vue-query'
import type { DefaultApiVerificationCodeControllerUploadVerificationCodesRequest } from '@peter-park/user'
import { useOpenApiClient } from './useUserOpenApiClient'

export function useUploadCSVFile() {
  const { apiInstance } = useOpenApiClient()

  const {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    mutate,
  } = useMutation({
    mutationFn: (payload: DefaultApiVerificationCodeControllerUploadVerificationCodesRequest) => apiInstance.value.verificationCodeControllerUploadVerificationCodes(payload),
    mutationKey: ['uploadCSVFile'],
    networkMode: 'always',
  })
  return {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    mutate,
  }
}
