<script lang="ts" setup>
defineOptions({
  name: 'FormPriceGroup',
})

const props = defineProps<PriceGroupProps>()
const { t } = useI18n()
interface PriceGroupProps {
  /**
   * The name of the input, it's needed for vee-validate to work properly
   */
  name: string
  /**
   * Options to display
   */
  options: { value: string, label: string, description: string, id: string }[]
  /**
   * Displayed
   */
  loading?: boolean
}
</script>

<template>
  <div class="flex flex-col gap-4">
    <!-- We need to add a placeholder option if there are no options otherwise the form will change the groupIds to undefined and when we read the options it will push to values to undefined not to an empty array -->
    <div v-if="!props.options.length">
      <FormPriceGroupOption v-show="false" key="placeholder" :name="props.name" value="0" label="'placeholder'" description="placeholder" />
      <div class="flex flex-col gap-1 overflow-auto b-1 b-red-200 rounded-xl bg-red-25 px-5 py-5 md:overflow-unset">
        <span class="text-sm text-red-600 leading-5">{{ t('account_verification.no_group_error') }}</span>
      </div>
    </div>
    <FormPriceGroupOption v-for="option in props.options" :key="option.id" :name="props.name" :value="option.id" :label="option.label" :description="option.description" />
  </div>
</template>
