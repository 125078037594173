import { useAuth0 } from '@auth0/auth0-vue'
import type { MaybeRef, ShallowRef } from 'vue'
import { authentication } from '@/stores/authentication'

import type { ConfigurationParameters, ErrorContext, Middleware, RequestContext, ResponseContext } from '@/types/openapiRuntime'

interface Newable<T> { new (...args: any[]): T }

const developmentLoggingMiddleware: Middleware = {
  pre: async (context: RequestContext) => {
    if (import.meta.env.DEV)
      // eslint-disable-next-line no-console
      console.info('Middleware: Request Context', context)
  },
  post: async (context: ResponseContext) => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('Middleware: Response Context', context)
    }
  },
  onError: async (context: ErrorContext) => {
    if (import.meta.env.DEV) {
      // eslint-disable-next-line no-console
      console.info('Middleware: Error Context', context)
    }
  },
}

export function useGenericOpenApiClient<C, D>(
  servicePath: string,
  ConfigClass: Newable<C>,
  DefaultApi: Newable<D>,
  configOverride?: MaybeRef<ConfigurationParameters>,
): {
    configurationParameters: MaybeRef<ConfigurationParameters>
    configuration: ShallowRef<C>
    apiInstance: ShallowRef<D>
  } {
  const authenticationStore = authentication()
  const { getAccessTokenSilently } = useAuth0()

  const refreshTokenMiddleware: Middleware = {
    onError: async (context: ErrorContext) => {
      // if the error is a 401, we can refresh. Tanstack query will
      if (context.response?.status === 401) {
        // refresh token
        const newToken = await getAccessTokenSilently()
        authenticationStore.setAccessToken(newToken)
      }
    },
  }

  const middleware = computed((): Middleware[] => {
    return [
      refreshTokenMiddleware,
      import.meta.env.DEV ? developmentLoggingMiddleware : {},
      ...(unref(configOverride)?.middleware ?? []),
    ]
  })

  const configurationParameters = computed((): ConfigurationParameters => {
    return {
      basePath: `${import.meta.env.VITE_TSBACKEND_INTERNAL_BASE_URL}/${servicePath}`,
      headers: {
        Authorization: `Bearer ${authenticationStore.accessToken}`,
      },
      middleware: unref(middleware),
      ...(unref(configOverride)),
    }
  })

  const configuration = shallowRef<C>(new ConfigClass(unref(configurationParameters)))
  const apiInstance = shallowRef<D>(new DefaultApi(unref(configuration)))

  function recreate() {
    configuration.value = new ConfigClass(unref(configurationParameters))
    apiInstance.value = new DefaultApi(unref(configuration))
  }

  onMounted(() => {
    recreate()
  })
  watch([configurationParameters], () => {
    recreate()
  })

  return {
    configurationParameters,
    configuration,
    apiInstance,
  }
}
