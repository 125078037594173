<script setup lang="ts" generic="DataType">
import type { Table } from '@tanstack/vue-table'

defineOptions({
  name: 'Pagination',
})

const props = withDefaults(
  defineProps<{
    table: Table<DataType>
    hasNextPage?: boolean
    hasPreviousPage?: boolean
    currentPage: number
  }>(),
  {
    hasNextPage: false,
    hasPreviousPage: false,
  },
)

const emits = defineEmits<{
  loadNext: []
  loadPrevious: []
}>()

const { t } = useI18n()
const { hasNextPage, hasPreviousPage, table } = toRefs(props)

const canGoNext = computed(() => hasNextPage.value || table.value.getCanNextPage())
const canGoPrevious = computed(() => hasPreviousPage.value || table.value.getCanPreviousPage())

function nextPage() {
  if (table.value.getCanNextPage())
    table.value.nextPage()
  else if (hasNextPage.value)
    emits('loadNext')
}

function previousPage() {
  if (table.value.getCanPreviousPage())
    table.value.previousPage()
  else if (hasPreviousPage.value)
    emits('loadPrevious')
}

const nextPageLabel = computed(() => {
  if (table.value.getCanNextPage())
    return t('next')
  if (hasNextPage.value)
    return t('load_more')
  return t('next')
})

const pageCount = computed(() => table.value.getPageCount())
</script>

<template>
  <div class="flex justify-between px-2 pt-4 lg:px-6">
    <BaseButton :disabled="!canGoPrevious" color="outline-gray" @click="previousPage">
      <span class="flex items-center gap-2">
        <div class="i-heroicons-arrow-left min-h-5 min-w-5" />
        <span class="hidden lg:block" :class="{ 'sm:block': pageCount < 5 }">{{ t('previous') }}</span>
      </span>
    </BaseButton>
    <BaseTablePaginationNumbers :current-page="props.currentPage" :pages="pageCount" @page-change="table.setPageIndex" />
    <BaseButton :disabled="!canGoNext" color="outline-gray" @click="nextPage">
      <span class="flex items-center gap-2">
        <span class="hidden lg:block" :class="{ 'sm:block': pageCount < 5 }">{{ nextPageLabel }}</span>
        <div class="i-heroicons-arrow-right min-h-5 min-w-5" />
      </span>
    </BaseButton>
  </div>
</template>
