<script lang="ts" setup>
import type { BreadcrumbPage } from '@/types/components'

defineOptions({
  name: 'Breadcrumbs',
})

const props = defineProps<{
  pages: BreadcrumbPage[]
}>()
</script>

<template>
  <nav class="w-full flex items-center justify-between" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-4">
      <li>
        <div>
          <RouterLink to="/" class="text-gray-primary hover:text-gray-700">
            <div class="i-custom:dashboard h-5 w-5 flex-shrink-0" aria-hidden="true" />
          </RouterLink>
        </div>
      </li>
      <li class="block !ml-1 sm:hidden sm:!ml-2">
        <div class="flex items-center">
          <div class="i-heroicons:chevron-right h-5 w-3 flex-shrink-0 text-gray-300" aria-hidden="true" />
          <span class="ml-1 text-sm text-gray-600 font-medium sm:ml-2">...</span>
        </div>
      </li>
      <li v-for="page in props.pages" :key="page.href" class="group hidden !ml-1 last:block sm:block sm:!ml-2">
        <div class="flex items-center">
          <div class="i-heroicons:chevron-right h-5 w-3 flex-shrink-0 text-gray-300" aria-hidden="true" />
          <RouterLink
            v-if="!page.loading"
            :to="page.href" class="text-sm text-gray-600 font-medium transition sm:ml-1 sm:ml-2 group-last:text-gray-700 group-last:font-semibold hover:text-gray-700"
          >
            {{ page.label }}
          </RouterLink>
          <div v-else class="h-5 w-30 animate-pulse rounded bg-gray-300" />
        </div>
      </li>
    </ol>
  </nav>
</template>
