<script setup lang="ts">
defineOptions({
  name: 'AccountVerificationConfirm',
})

const props = defineProps<{
  area: string
  group: string
  fileName: string
  fileSize: string
  uploadedAccountsNumber: number
}>()

const { t } = useI18n()

const headers = computed(() => {
  return [t('file_name'), t('uploaded_accounts'), t('price_groups'), t('area')]
})
</script>

<template>
  <div class="flex flex-col overflow-auto">
    <div class="grid min-w-3xl items-center">
      <div class="grid cols-4 b-1 b-gray-200 rounded-t-xl bg-gray-25 px-6 py-3 text-xs">
        <span v-for="(header, idx) in headers" :key="idx">
          {{ header }}
        </span>
      </div>
      <div class="grid cols-4 gap-1 b-b-1 b-l-1 b-r-1 b-gray-200 rounded-b-xl bg-white px-6 py-3 text-sm">
        <div class="flex items-center gap-3">
          <div class="i-custom:file-attachment min-h-10 min-w-10" />
          <div class="flex flex-col">
            <span class="break-anywhere">{{ props.fileName }}</span>
            <span class="text-gray-400">{{ props.fileSize }}</span>
          </div>
        </div>
        <span>{{ props.uploadedAccountsNumber }}</span>
        <span>{{ props.group }}</span>
        <span>{{ props.area }}</span>
      </div>
    </div>
  </div>
</template>
