<script lang="ts" setup>
defineOptions({
  name: 'AppHeaderAreaSelectorVisualizerToggleSelectAll',
})

const props = defineProps<{
  /**
   * If everything is selected, so we can change the icon
   */
  isAnythingSelected: boolean
}>()
const emit = defineEmits<{
  /**
   * Emit when the user hover the button
   */
  hover: [value: boolean]
  /**
   * Emit when the user clicks on select all
   */
  selectAll: []
  /**
   * Emit when the user clicks on deselect all
   */
  deselectAll: []
}>()
const { t } = useI18n()
const toggleIcon = computed(() => {
  return props.isAnythingSelected ? 'i-custom:paragraph-spacing' : 'i-custom:select-all'
})
const toggleText = computed(() => {
  return props.isAnythingSelected ? t('deselect_all') : t('select_all')
})
const toggleButtonElement = ref()
const isHovered = useElementHover(toggleButtonElement)
watch(isHovered, (value) => {
  emit('hover', value)
})

function handleClick() {
  if (props.isAnythingSelected) {
    emit('deselectAll')
  }
  else {
    emit('selectAll')
  }
}
</script>

<template>
  <button
    ref="toggleButtonElement"
    class="h-full border-1 rounded-lg px-2 py-2 text-white leading-none shadow transition-all"
    :class="[
      { 'border-white/40 bg-white/20': !isHovered },
      { 'border-white bg-ppGreenMediumDark': isHovered },
    ]"
    @click="handleClick()"
  >
    <div class="flex items-center rounded-lg bg-transparent leading-none">
      <div class="h-5 w-5" :class="toggleIcon" />
      <p
        class="text-xs font-600 leading-none transition-all"
        :class="[
          { 'max-w-0 h-0 opacity-0 pointer-events-none': !isHovered },
          { 'max-w-100 gap-1.5 h-full opacity-100 ml-1.5': isHovered },
        ]"
      >
        {{ toggleText }}
      </p>
    </div>
  </button>
</template>
