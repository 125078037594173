<script setup lang="ts">
import type { ButtonColors } from '@/components/Base/Button/types'

const props = defineProps<{
  open: boolean
  loading: boolean
  headline: string
  cancelText: string
  confirmText: string
  confirmColor?: ButtonColors
}>()

const emits = defineEmits<{
  clickCancel: []
  clickConfirm: []
}>()
</script>

<template>
  <Dialog :open="props.open" :loading="props.loading">
    <template #headline>
      <p>{{ props.headline }}</p>
    </template>
    <template #content>
      <slot />
    </template>
    <template #buttons>
      <BaseButton color="outline-gray" size="sm" full @click="emits('clickCancel')">
        {{ props.cancelText }}
      </BaseButton>
      <BaseButton size="sm" full :color="props.confirmColor" @click="emits('clickConfirm')">
        {{ props.confirmText }}
      </BaseButton>
    </template>
  </Dialog>
</template>
