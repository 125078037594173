<script lang="ts" setup>
import { logNavigation } from '@/utils/logger'

defineOptions({
  name: 'SidebarLink',
})
const props = defineProps<{
  name: string
  icon?: string
  locationName?: string
  child?: boolean
}>()

const route = useRoute()
function onLinkClick() {
  logNavigation(props.name)
}
</script>

<template>
  <RouterLink
    v-if="props.locationName" :to="{ name: props.locationName }"
    class="group flex gap-x-3 px-3 py-2 font-semibold leading-6 transition"
    :class="[
      route.name === props.locationName ? 'text-ppGreenDeep b-ppGreenDeep' : 'text-gray-primary hover:text-ppGreenDeep',
      props.child ? 'pl-8 b-l-2' : '',
    ]"
    @click="onLinkClick"
  >
    <div
      v-if="props.icon"
      class="h-6 w-6 shrink-0 transition"
      :class="[icon, route.name === props.locationName ? 'text-ppGreenDeep' : 'text-gray-primary group-hover:text-ppGreenDeep']" aria-hidden="true"
    />
    {{ props.name }}
  </RouterLink>
  <div
    v-else
    class="group flex gap-x-3 p-2 font-semibold leading-6 transition"
    :class="[route.name === props.locationName ? 'text-ppGreenDeep' : 'text-gray-primary hover:text-ppGreenDeep']"
  >
    <div
      class="h-6 w-6 shrink-0 transition"
      :class="[icon, route.name === props.locationName ? 'text-ppGreenDeep' : 'text-gray-primary group-hover:text-ppGreenDeep']" aria-hidden="true"
    />
    {{ props.name }}
  </div>
</template>
