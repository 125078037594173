<script lang="ts" setup>
import { useField } from 'vee-validate'

defineOptions({
  name: 'FormCheckbox',
})
const props = defineProps<CheckboxProps>()
interface CheckboxProps {
  /**
   * The name of the checkbox, it's needed for vee-validate to work properly
   */
  name: string
  /**
   * The value of the checkbox
   */
  value: string
}
const { name } = toRefs(props)
const { checked, handleChange } = useField(name, undefined, {
  type: 'checkbox',
  checkedValue: props.value,
  validateOnMount: true,
})

const uncheckedClasses = 'ring-1 ring-gray-300 bg-white'
const checkedClasses = 'ring-1 ring-ppGreenDeep bg-ppGreenDeep'
</script>

<template>
  <button type="button" class="w-full flex items-center justify-between bg-transparent" @click="handleChange(value)">
    <slot />
    <div class="m-0 min-h-5 min-w-5 flex items-center justify-center rounded-lg p-0 transition-all" :class="checked ? checkedClasses : uncheckedClasses">
      <div v-if="checked" class="i-lucide:check h-4 w-4 text-white" />
    </div>
  </button>
</template>
