<script lang="ts" setup>
defineOptions({
  name: 'WizardSteps',
})

const props = defineProps<{
  desktopWidth: string
  currentStepIndex: number
  steps: {
    name: string
    content?: string
    current: boolean
    valid: boolean
  }[]
}>()

const currentStepName = computed(() => props.steps.find(s => s.current === true)?.name ?? '')
</script>

<template>
  <div class="w-full bg-gray-100 px-10 py-5 md:hidden">
    <div class="flex items-center gap-5">
      <div class="h-2 w-2 rounded-full bg-pp-green-neon" />
      <p class="text-sm font-600 leading-none">
        {{ currentStepName }}
      </p>
    </div>
  </div>
  <div class="h-fit rounded-xl p-6 ring-1 ring-gray-100 hidden md:block" :class="desktopWidth">
    <div v-for="(step, index) in props.steps" :key="index" class="mb-3">
      <div class="mb-2 flex items-center gap-5">
        <div class="h-2.5 w-2.5 rounded-full" :class="step.valid || step.current ? 'bg-pp-green-neon' : 'bg-gray-300' " />
        <p class="text-sm font-600 leading-none" :class="step.valid ? 'text-pp-green-neon' : '' ">
          {{ step.name }}
        </p>
      </div>
      <div class="flex items-stretch gap-5">
        <div class="w-2.5 flex items-stretch justify-center" :class="step.content && step.valid && props.currentStepIndex !== index ? 'h-8' : 'h-4'">
          <div class="h-full border-1 rounded-full bg-gray-300" />
        </div>
        <p v-if="step.content && step.valid && props.currentStepIndex !== index" class="self-start text-sm text-gray-600 leading-none">
          {{ step.content }}
        </p>
      </div>
    </div>
  </div>
</template>
