<script lang="ts" setup>
defineOptions({
  name: 'ActionsButton',
})

const props = withDefaults(defineProps<{
  color?: string
  disabled?: boolean
  hintText?: string
}>(), {
  color: 'gray',
})

const colorMap = new Map<string, string>([
  ['gray', 'text-gray-600'],
  ['red', 'text-red-600'],
  ['green', 'text-green-600'],
  ['primary', 'text-gray-primary'],
])

const colorToClasses = computed(() => {
  return [colorMap.get(props.color)]
})
</script>

<template>
  <div class="relative overflow-visible">
    <button
      id="button"
      type="button"
      :class="colorToClasses"
      :disabled="props.disabled"
      class="group/action relative rounded-full bg-transparent p-1 leading-none hover:bg-gray-100 disabled:text-gray-300 !disabled:bg-transparent"
    >
      <slot />
      <div
        v-if="!props.disabled && props.hintText"
        class="absolute bottom-[calc(100%+0.5rem)] left-1/2 max-w-25 transform whitespace-normal rounded-xl bg-pp-green-deep px-3 py-2 text-xs text-white font-600 opacity-0 transition-opacity duration-300 ease-in-out -translate-x-50% group-hover/action:opacity-100"
      >
        {{ props.hintText }}
        <svg class="absolute left-0% top-full h-2 w-full text-pp-green-deep" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0" /></svg>
      </div>
    </button>
  </div>
</template>
