<script setup lang="ts">
const open = ref(false)

const route = useRoute()
watch(route, () => {
  open.value = false
})
</script>

<template>
  <button class="rounded-2 bg-white/30 p-2 md:hidden" @click="open = !open">
    <div class="i-lucide:menu h-6 w-6 bg-#FFFFFF" :class="{ 'i-lucide:menu': !open, 'i-lucide:x': open }" />
  </button>
  <Teleport to="body">
    <Transition
      enter-active-class="transition duration-300 ease-out" enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-300" leave-active-class="transition duration-200 ease-out"
      leave-from-class="transform opacity-300" leave-to-class="transform opacity-0"
    >
      <div v-if="open" class="fixed top-18 z-30 max-h-[calc(100%-4rem)] w-full overflow-y-scroll">
        <AppNavigator />
      </div>
    </Transition>
  </Teleport>
</template>
