<script lang="ts" setup>
defineOptions({
  name: 'WizardSection',
})
const props = defineProps<{
  title: string
  description?: string
}>()
</script>

<template>
  <div>
    <WizardSectionTitle>
      <template #title>
        {{ props.title }}
      </template>
      <template v-if="props.description" #description>
        {{ props.description }}
      </template>
    </WizardSectionTitle>
    <div class="mt-6">
      <slot />
    </div>
  </div>
</template>
