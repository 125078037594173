<script lang="ts" setup>
import { toast } from 'vue-sonner'
import { formatAreaName } from '@/utils/areas'

import { useGetVerificationSummary } from '@/composables/openapi/user/useGetVerificationSummary'

defineOptions({
  name: 'AccountVerificationSummary',
})

const { t } = useI18n()
const route = useRoute()

const areaEntityId = Array.isArray(route.params.areaId) ? route.params.areaId[0] : route.params.areaId

const { data: verificationSummaryArray, error: getVerificationSummaryError, isPending } = useGetVerificationSummary()

const selectedArea = computed(() => {
  return verificationSummaryArray.value?.find(area => area.areaEntityId === areaEntityId)
})

const breadcrumbs = computed(() => {
  return [
    {
      label: t('sidebar.account_verification'),
      href: '/account-verification',
    },
    {
      label: selectedArea.value ? formatAreaName(selectedArea.value) : '',
      href: `/account-verification/${route.params.areaId}`,
      loading: isPending.value,
    },
  ]
})

watch(getVerificationSummaryError, (error) => {
  if (error) {
    toast.error(t('error_fallback'))
  }
})
</script>

<template>
  <div class="mx-4 my-4 flex flex-col gap-6 lg:mx-8 lg:gap-8">
    <BaseBreadcrumb :pages="breadcrumbs" />
    <div class="lg:b-b-1 lg:pb-6">
      <h4 class="text-3xl color-gray-900 font-bold leading-9">
        {{ t('account_verification.title') }}
      </h4>
      <h4 class="text-base color-gray-600 leading-6">
        {{ t('account_verification.summary.subtitle') }}
      </h4>
    </div>
    <AccountVerificationSummaryTable :total-count="selectedArea?.totalUploadedCodesCount" :total-count-loading="isPending" />
  </div>
</template>
