<script lang="ts" setup>
import type { ButtonProps } from './types'

withDefaults(defineProps<ButtonProps>(), {
  color: 'primary',
  size: 'md',
  type: 'button',
  disabled: false,
})

const colorToClasses = new Map([
  ['primary', '!bg-transition-gradient-to-r from-ppGreenDark via-ppGreenLight to-ppGreenDark text-white transition-background-position'],
  ['secondary', '!bg-transition-gradient-to-r from-ppGreenDark via-ppGreenDark to-ppGreenLight text-white transition-background-position'],
  ['danger', 'bg-red-500 hover:bg-red-700 text-white transition'],
  ['success', 'bg-green-500 hover:bg-green-700 text-white transition'],
  ['warning', 'bg-yellow-500 hover:bg-yellow-700 text-white transition'],
  ['outline-gray', 'ring-1 ring-gray-300 text-gray-700 hover:bg-gray-100 bg-white'],
])
const sizeToClasses = new Map([
  ['sm', 'min-h-8  px-2 py-1 text-sm'],
  ['md', 'min-h-10 px-3 py-2 text-base'],
  ['lg', 'min-h-15 px-4 py-3 text-lg'],
])
const disabledClasses = 'disabled:cursor-not-allowed disabled:opacity-50'
</script>

<template>
  <button
    class="rounded-md"
    :type="type"
    :disabled="disabled"
    :class="[colorToClasses.get(color), sizeToClasses.get(size), disabledClasses, full ? 'w-full' : '']"
  >
    <slot />
  </button>
</template>
