import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { VerificationCodeDeleteDto } from '@peter-park/user'
import { useOpenApiClient } from './useUserOpenApiClient'

export function useDeleteVerificationCodes(areaEntityId: string) {
  const { apiInstance } = useOpenApiClient()
  const queryClient = useQueryClient()

  const {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    mutate,
  } = useMutation({
    mutationFn: (payload: VerificationCodeDeleteDto) => apiInstance.value.verificationCodeControllerDelete({ verificationCodeDeleteDto: payload }),
    onSuccess: (_response) => {
      queryClient.invalidateQueries({ queryKey: ['verificationCodesByArea', areaEntityId] })
      queryClient.invalidateQueries({ queryKey: ['verificationSummary'] })
    },
    mutationKey: ['deleteVerificationCodes'],
    networkMode: 'always',
  })
  return {
    isPending,
    isError,
    isSuccess,
    data,
    error,
    mutate,
  }
}
