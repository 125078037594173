<script lang="ts" setup>
import { useAuth0 } from '@auth0/auth0-vue'

const { t } = useI18n()
const { user } = useAuth0()
</script>

<template>
  <div class="mx-auto p-8">
    <span class="text-2xl text-gray-900 font-semibold">
      {{ t('welcome') }}, {{ user?.name }}
    </span>
    <span class="flex text-gray-600 font-light">{{ t('dashboard_underline_message') }}</span>
  </div>
</template>
