<script lang="ts" setup>
</script>

<template>
  <div class="h-screen w-screen bg-bluegray-50 text-bluegray-900 font-sans">
    <AppHeader />
    <div class="hidden md:block">
      <AppNavigator />
    </div>
    <main class="h-full bg-transparent pt-18 md:pl-78 md:pt-20">
      <div class="relative z-25 h-full w-full overflow-auto bg-white md:rounded-tl-3xl">
        <slot />
      </div>
    </main>
  </div>
</template>
