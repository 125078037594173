<script setup lang="ts" generic="T">
import { useVirtualList } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    /**
     * The list of items to render
     */
    items: T[]
    /**
     * The height of each item
     */
    itemHeight: number
    /**
     * Maximum length of the list before enabling virtual scrolling
     * @default 100
     */
    maxItems?: number
    /**
     * The number of items to render outside the visible area of virtual scroller
     * @default 10
     */
    overscan?: number
  }>(),
  {
    maxItems: 100,
    overscan: 10,
  },
)

const { items, maxItems } = toRefs(props)

const { list, containerProps, wrapperProps } = useVirtualList(items, {
  itemHeight: props.itemHeight,
  overscan: props.overscan,
})

const virtualListEnabled = computed(() => {
  return maxItems.value > items.value.length
})
</script>

<template>
  <div v-if="virtualListEnabled" class="overflow-auto">
    <slot v-for="(item, index) in items" :key="index" :data="item" />
  </div>
  <div v-else class="max-h-100 overflow-auto" v-bind="containerProps">
    <div v-bind="wrapperProps">
      <slot v-for="item in list" :key="item.index" :data="item.data" />
    </div>
  </div>
</template>
