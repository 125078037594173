<script lang="ts" setup generic="DataType">
import type { Row } from '@tanstack/vue-table'
import { FlexRender } from '@tanstack/vue-table'

defineOptions({
  name: 'TableBodyRow',
})

const props = defineProps<{
  rows: Row<DataType>[]
  selectable?: boolean
}>()
</script>

<template>
  <tr v-for="row in props.rows" :key="row.id" class="sm:h-18 even:bg-white">
    <td v-if="props.selectable" class="b-b-1 px-6 py-4 !w-fit">
      <BaseTableCheckbox v-if="props.selectable" :value="row.getIsSelected()" class="w-fit py-2 pr-2" @on-change="row.toggleSelected()" />
    </td>
    <BaseTableCell v-for="(cell) in row.getVisibleCells()" :key="cell.id">
      <FlexRender :render="cell.column.columnDef.cell" :props="cell.getContext()" />
    </BaseTableCell>
    <slot name="additionalColumns" :row="row" />
    <slot name="actions" :row="row" />
  </tr>
</template>
