<script lang="ts" setup>
import { logCloseWizard } from '@/utils/logger'

defineOptions({
  name: 'WizardLayout',
})

const { t } = useI18n()

const route = useRoute()
const router = useRouter()
const routeLink = computed(() => {
  const split = route.path.split('/')
  return split.slice(0, split.length - 1).join('/')
})

const closeDialogIsOpen = ref(false)

function closeWizard() {
  logCloseWizard()
  router.push(routeLink.value)
}
</script>

<template>
  <div class="h-screen w-screen flex flex-col justify-between">
    <header class="h-18 w-full flex items-center bg-pp-green px-3">
      <div class="mx-auto flex items-center justify-between container">
        <div class="flex shrink-0 items-center justify-between">
          <router-link to="/">
            <img src="@/assets/logo.svg" class="h-8" alt="Peter Park">
          </router-link>
        </div>
        <button class="rounded-2 bg-white/30 p-2" @click="closeDialogIsOpen = !closeDialogIsOpen">
          <div class="i-lucide:x h-6 w-6 bg-#FFFFFF" />
        </button>
        <Dialog :open="closeDialogIsOpen" buttons-align="right">
          <template #content>
            <p>{{ t('account_verification.dialog.close') }}</p>
          </template>
          <template #buttons>
            <BaseButton color="outline-gray" size="sm" @click="closeDialogIsOpen = false">
              {{ t('cancel') }}
            </BaseButton>
            <BaseButton color="danger" size="sm" @click="closeWizard()">
              {{ t('continue') }}
            </BaseButton>
          </template>
        </Dialog>
      </div>
    </header>
    <div class="flex grow flex-col justify-between overflow-x-hidden overflow-y-auto">
      <slot />
    </div>
  </div>
</template>
