export * from './auth0'
export * from './logger'
export * from './routeGuard'
export * from './api'

type OperatingSystem = 'Windows' | 'MacOS' | 'UNIX' | 'Linux' | 'Android' | 'iOS' | 'Unknown OS'
export function getOS(): OperatingSystem {
  const userAgent = navigator.userAgent
  let os: OperatingSystem = 'Unknown OS'

  if (userAgent.includes('Win'))
    os = 'Windows'
  else if (userAgent.includes('Mac'))
    os = 'MacOS'
  else if (userAgent.includes('X11'))
    os = 'UNIX'
  else if (userAgent.includes('Linux'))
    os = 'Linux'
  else if (userAgent.includes('Android'))
    os = 'Android'
  else if (userAgent.includes('like Mac'))
    os = 'iOS'

  return os
}
